import * as React from 'react';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileBox } from '../../../../common/components/attachment';
import { getIsAuthorized } from '../../../../common/store/user/user.selectors';
import { CollapsablePanel } from '../../../../components';
import { RequestLocationsActions, StorageActions } from '../../../../store/actions';
import {
  getStorageFiles,
  selectLocationDetail,
  selectWithUtilityCompanyLimitations,
} from '../../../../store/selectors';
import {
  BulkDownloadTypes,
  IFile,
  IntakeTypes,
  IPublicDomainIntakeExtended,
  IRequestAclActions,
} from '../../../../types';
import { useGeneralStyles } from '../../../../style/generalStyles';
import { translate } from '../../../../common/translations/translate';
import { Visible } from '../../../../common/components/layout/Visible.component';

interface IProps {
  requestId: string;
  location?: IPublicDomainIntakeExtended;
}

export const LocationAttachmentsContainer: FunctionComponent<IProps> = ({ location, requestId }) => {
  const dispatch = useDispatch();
  const G = useGeneralStyles();
  const { id, attachments = [], type } = useSelector(selectLocationDetail) || {};
  const uploadedFiles = useSelector(getStorageFiles);
  const canEditAssignedRequest = useSelector(getIsAuthorized([IRequestAclActions.editAssignedRequest]));
  const withUtilityCompanyLimitations = useSelector(selectWithUtilityCompanyLimitations);

  useEffect(() => {
    dispatch(StorageActions.clear());
    location && dispatch(RequestLocationsActions.fetch({ id: requestId, locationId: `${location.id}` }));
  }, [location, requestId, dispatch]);

  const uploadFile = useCallback(
    (file: IFile) => {
      dispatch(RequestLocationsActions.uploadFile(file));
    },
    [dispatch],
  );

  const removeFile = useCallback(
    (fileName: string) => {
      dispatch(RequestLocationsActions.removeFile(fileName));
    },
    [dispatch],
  );

  // This is code ready to be implemented when AS-5800 is done on BE
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const downloadZip = useCallback(
    (fileIds: string[]) => {
      dispatch(
        StorageActions.bulkDownload({
          type: BulkDownloadTypes.requestLocation,
          id: requestId,
          id2: id,
          fileIds,
        }),
      );
    },
    [dispatch, id, requestId],
  );

  return location ? (
    <CollapsablePanel
      title={translate(
        `Requests.Detail.Locations.Attachments.PanelTitle${
          type?.type === IntakeTypes.minorconstructionsiteintake ? 'SmallWork' : ''
        }`,
      )}
    >
      <div className={G.flexColumnFullWidth}>
        <Visible visible={!!withUtilityCompanyLimitations}>
          {translate('Requests.Detail.Locations.Attachments.addThreePictures')}
          <ul className={G.marginBottom40}>
            <li>{translate('Requests.Detail.Locations.Attachments.oneOfLocationOfStartSign')}</li>
            <li>{translate('Requests.Detail.Locations.Attachments.oneOfLocationOfEndSign')}</li>
            <li>{translate('Requests.Detail.Locations.Attachments.overviewPhotoOfParkedVehicles')}</li>
          </ul>
        </Visible>
        <div className={G.fullWidth}>
          <FileBox
            onFileSelect={canEditAssignedRequest ? uploadFile : undefined}
            onRemoveFile={canEditAssignedRequest ? removeFile : undefined}
            placeholder={translate(
              `Requests.Detail.Locations.Attachments.Placeholder${
                type?.type === IntakeTypes.minorconstructionsiteintake ? 'SmallWork' : ''
              }`,
            )}
            storedFiles={attachments}
            uploadedFiles={uploadedFiles}
          />
        </div>
      </div>
    </CollapsablePanel>
  ) : null;
};
