import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import { genericErrorHandler, TPayloadAction } from '../../common';
import { SgwPhasesActions } from '../actions/phases.actions';
import { ICall, ISelect } from '../../types';
import { SgwRequestsApi } from '../api/sgwRequests.api';
import {
  getGeoDrawingsByPhaseId,
  getPhaseById,
  getStandardPhases,
  selectSgwRequest,
} from '../selectors/sgwRequest.selectors';
import { SgwRequestActions, SnackBarActions } from '../actions';
import { translate } from '../../common/translations/translate';
import { QUARTER_COST_REFETCH_MS, QUARTER_COST_RETRY_REFETCH_MS } from '../../common/time-constants';

function* onFetchPhases(action: TPayloadAction<number>): SagaIterator {
  const response: ICall<typeof SgwRequestsApi.fetchPhases> = yield call(
    SgwRequestsApi.fetchPhases,
    `${action.payload}`,
  );
  yield put(SgwPhasesActions.setList(response!.data.data));
}

function* onSavePhase({ payload }: ReturnType<typeof SgwPhasesActions.save>): SagaIterator {
  const request: ISelect<typeof selectSgwRequest> = yield select(selectSgwRequest);
  if (payload.syncRequest) {
    yield put(SgwRequestActions.syncDates({ requestId: request?.id!, phase: payload.phase }));
    yield take(SgwRequestActions.set.type);
  }
  const phase = {
    ...payload.phase,
    sgwGeoDrawings: payload.phase.sgwGeoDrawings?.map((geoDrawing) =>
      !geoDrawing.sgwTrafficMeasures ? { ...geoDrawing, sgwTrafficMeasures: [] } : geoDrawing,
    ),
  };
  yield call(SgwRequestsApi.savePhase, `${request!.id}`, phase);
  yield put(SgwPhasesActions.fetchList(request!.id));
  yield* fetchQuarterCostWithRetry(request!.id);
}

function* fetchQuarterCost(delayMs: number, id: string | number, shouldThrowError: boolean = false) {
  try {
    yield put(SgwRequestActions.quarterCost.setLoading(true));
    yield delay(delayMs);
    const response: ICall<typeof SgwRequestsApi.fetchQuarterCost> = yield call(
      SgwRequestsApi.fetchQuarterCost,
      `${id}`,
    );
    yield put(SgwRequestActions.quarterCost.set(response!.data.data));
  } catch (e) {
    if (shouldThrowError) {
      throw e;
    }
  } finally {
    yield put(SgwRequestActions.quarterCost.setLoading(false));
  }
}

function* fetchQuarterCostWithRetry(requestId: string | number) {
  try {
    yield* fetchQuarterCost(QUARTER_COST_REFETCH_MS, requestId);
  } catch (e) {
    try {
      yield* fetchQuarterCost(QUARTER_COST_RETRY_REFETCH_MS, requestId, true);
    } catch (e) {
      yield put(SnackBarActions.setFailure(translate('sgw.requests.detail.retributionTable.errorFetchingQuarterCost')));
    }
  }
}

function* onCopyFromPhase({ payload }: ReturnType<typeof SgwPhasesActions.copyFromPhase>): SagaIterator {
  const geoDrawingsToCopy = yield select(getGeoDrawingsByPhaseId(payload.phaseIdToCopy));
  const phaseToSave = yield select(getPhaseById(payload.phaseIdToSave));
  yield put(
    SgwPhasesActions.save({
      phase: { ...phaseToSave, sgwGeoDrawings: [...phaseToSave.sgwGeoDrawings, ...geoDrawingsToCopy] },
    }),
  );
}

function* onRemove({ payload }: ReturnType<typeof SgwPhasesActions.remove>): SagaIterator {
  const standardPhases = yield select(getStandardPhases);
  try {
    yield call(SgwRequestsApi.removePhase, payload.requestId, payload.phaseId);
    yield put(SnackBarActions.setSuccess(translate('sgw.requests.detail.phases.removePhaseSuccess')));
  } catch (error) {
    yield put(SnackBarActions.setFailure(translate('sgw.requests.detail.phases.removePhaseFailed')));
  }
  yield put(SgwRequestActions.conflicts.sync(payload.requestId));
  yield put(SgwPhasesActions.fetchList(payload.requestId));
  yield put(SgwRequestActions.conflicts.fetchList(`${payload.requestId}`));
  if (standardPhases.length > 1) yield put(SgwRequestActions.syncDates({ requestId: payload.requestId }));
}

function* onClone({ payload }: ReturnType<typeof SgwPhasesActions.clone>): SagaIterator {
  yield call(SgwRequestsApi.clonePhase, payload.requestId, payload.phaseId, payload.phase);
  yield put(SgwPhasesActions.fetchList(parseInt(payload.requestId)));
  yield put(SgwRequestActions.conflicts.sync(parseInt(payload.requestId)));
  yield put(SgwRequestActions.attachments.fetch(payload.requestId));
  yield* fetchQuarterCostWithRetry(payload.requestId);
}

export function* sgwPhasesSaga(): SagaIterator {
  yield takeLatest(SgwPhasesActions.copyFromPhase.type, genericErrorHandler(onCopyFromPhase));
  yield takeLatest(SgwPhasesActions.fetchList.type, genericErrorHandler(onFetchPhases));
  yield takeLatest(SgwPhasesActions.save.type, genericErrorHandler(onSavePhase));
  yield takeLatest(SgwPhasesActions.remove.type, genericErrorHandler(onRemove));
  yield takeLatest(SgwPhasesActions.clone.type, genericErrorHandler(onClone));
}
