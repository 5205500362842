import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest, takeEvery, take, delay } from 'redux-saga/effects';
import { genericErrorHandler, handleServerError, initialRequestsState, TPayloadAction } from '../../common';
import { getUserCanOnlySeeAdvisingPartyRequests, getUsername } from '../../common/store/user/user.selectors';
import { SYNC_CONFLICTS_TIMEOUT_MS } from '../../common/time-constants';
import { getPagingFromHeaders } from '../../common/utils/api.util';
import { IRootState } from '../../root.state';
import {
  DateFormat,
  ICall,
  IReselect,
  IRequestMessage,
  ISelect,
  ISgwRequestBE,
  ISgwRequestCountersFilter,
  ISgwRequestPostTransition,
  HttpStatusCodes,
} from '../../types';
import { setRequestMessageAttachments, setRequestMessagesList, SgwRequestActions, SnackBarActions } from '../actions';
import { RequestsApi } from '../api';
import { SgwRequestsApi } from '../api/sgwRequests.api';
import {
  getHighestDateUntil,
  getLowestDateFrom,
  getPagedApiParams,
  getSgwRequestNotes,
  selectConflictsLoading,
} from '../selectors/sgwRequest.selectors';
import moment from 'moment';
import { translate } from '../../common/translations/translate';
import { getIsOnlyRoleUtilities, selectUser } from '../selectors';
import { mapToRequestMessageBE } from '../../utils';
import { SgwPhasesActions } from '../actions/phases.actions';

function* onFetchRequestList() {
  const userCanOnlySeeAdvisingPartyRequests: ISelect<typeof getUserCanOnlySeeAdvisingPartyRequests> = yield select(
    getUserCanOnlySeeAdvisingPartyRequests,
  );
  const params: ISelect<typeof getPagedApiParams> = yield select(getPagedApiParams);

  const defaultParams = {
    sort: initialRequestsState.list.table.sorting.key,
    order: initialRequestsState.list.table.sorting.direction,
    page: initialRequestsState.list.table.paging.page,
    page_size: initialRequestsState.list.table.paging.pageSize,
  };

  const mergedParams = { ...defaultParams, ...params };

  let parsedParams = mergedParams;

  if (mergedParams.referenceId && mergedParams.filterOnlyOnReferenceId) {
    parsedParams = {
      referenceId: mergedParams?.referenceId,
      sort: mergedParams.sort,
      order: mergedParams.order,
      page: mergedParams.page,
      page_size: mergedParams.page_size,
    };
  }

  const response: ICall<typeof SgwRequestsApi.fetchList> = yield call(
    SgwRequestsApi.fetchList,
    parsedParams,
    userCanOnlySeeAdvisingPartyRequests,
  );

  yield all([
    put(SgwRequestActions.list.set(response!.data.data)),
    put(
      SgwRequestActions.list.setParams({
        paging: getPagingFromHeaders(response as any),
      }),
    ),
  ]);
}

function* onFetchRequestCounters() {
  const params: ISelect<typeof getPagedApiParams> = yield select(getPagedApiParams);
  const countersParams: ISgwRequestCountersFilter = {
    clusters: params?.clusters,
    myRequests: params?.myRequests,
  };
  const response: ICall<typeof SgwRequestsApi.fetchRequestCounters> = yield call(
    SgwRequestsApi.fetchRequestCounters,
    countersParams,
  );

  yield put(SgwRequestActions.setRequestCounters(response!.data.data));
}

function* onFetchRequest(action: TPayloadAction<string>): SagaIterator {
  try {
    const response: ICall<typeof SgwRequestsApi.fetch> = yield call(SgwRequestsApi.fetch, action.payload);
    yield put(SgwRequestActions.set(response!.data.data));
  } finally {
    yield put(SgwRequestActions.setLoading(false));
  }
}

function* onFetchSgwRequestMessages(action: TPayloadAction<number>): SagaIterator {
  const isOnlyRoleUtilities = yield select(getIsOnlyRoleUtilities);
  if (isOnlyRoleUtilities) return;

  const response: ICall<typeof RequestsApi.getRequestMessages> = yield call(
    SgwRequestsApi.fetchSgwRequestMessages,
    `${action.payload}`,
  );
  yield put(setRequestMessagesList(response!.data.data));
}

function* onSaveSgwRequestMessage({ payload }: TPayloadAction<IRequestMessage>): SagaIterator {
  try {
    const response: ICall<typeof SgwRequestsApi.saveSgwRequestMessage> = yield call(
      SgwRequestsApi.saveSgwRequestMessage,
      payload.requestId!,
      mapToRequestMessageBE(payload),
    );
    yield put(setRequestMessagesList(response!.data.data));
  } finally {
    yield put(setRequestMessageAttachments(initialRequestsState.requestMessageAttachments));
  }
}

function* onResolveSgwRequestMessage(action: TPayloadAction<string>): SagaIterator {
  const response: ICall<typeof SgwRequestsApi.resolveSgwRequestMessages> = yield call(
    SgwRequestsApi.resolveSgwRequestMessages,
    action.payload,
  );
  yield put(setRequestMessagesList(response!.data.data));
}

function* onRequestAssign(action: TPayloadAction<{ id: number; assign: boolean }>): SagaIterator {
  const user: ISelect<typeof selectUser> = yield select(selectUser);
  const payload: Partial<ISgwRequestBE> = {
    id: action.payload.id,
    userResponsible: action.payload.assign ? `${user!.id}` : null,
  };
  yield put(SgwRequestActions.patch(payload));
}

function* onPostRequestTransition(action: TPayloadAction<ISgwRequestPostTransition>): SagaIterator {
  const response: ICall<typeof SgwRequestsApi.postTransitionState> = yield call(
    SgwRequestsApi.postTransitionState,
    action.payload,
  );
  yield put(SgwRequestActions.set(response!.data.data));
}

function* onPatchRequest(action: TPayloadAction<Partial<ISgwRequestBE>>) {
  const response: ICall<typeof SgwRequestsApi.patch> = yield call(SgwRequestsApi.patch, action.payload);
  yield put(SgwRequestActions.set(response!.data.data));
}

function* onPatchInternalNotes({ payload }: ReturnType<typeof SgwRequestActions.patchInternalNotes>): SagaIterator {
  const userName: ISelect<typeof getUsername> = yield select(getUsername());
  const notes: IReselect<typeof getSgwRequestNotes> = yield select(getSgwRequestNotes());
  const delimiter = notes!.length ? '\n\n' : '';

  const backofficeNotes = payload.shouldAppend
    ? `${notes}${delimiter}${userName} (${moment().format(DateFormat.dateTime)}):\n${payload.backofficeNotes}`
    : payload.backofficeNotes;

  const response: ICall<typeof SgwRequestsApi.patchInternalNotes> = yield call(SgwRequestsApi.patchInternalNotes, {
    backofficeNotes,
    id: payload.id,
  });

  yield put(SgwRequestActions.set(response!.data.data));
}

function* onFetchAttachments({ payload }: ReturnType<typeof SgwRequestActions.attachments.fetch>): SagaIterator {
  yield put(SgwRequestActions.attachments.setLoading(true));

  const response: ICall<typeof SgwRequestsApi.fetchAttachments> = yield call(SgwRequestsApi.fetchAttachments, payload);

  yield put(SgwRequestActions.attachments.set(response!.data.data));
  yield put(SgwRequestActions.attachments.setLoading(false));
}

function* onSaveAttachments({ payload }: ReturnType<typeof SgwRequestActions.attachments.save>): SagaIterator {
  yield call(SgwRequestsApi.saveAttachments, payload.requestId, payload.attachment);
  yield put(SgwRequestActions.attachments.fetch(payload.requestId));
}

function* onCopyAttachment({ payload }: ReturnType<typeof SgwRequestActions.attachments.copy>): SagaIterator {
  yield call(SgwRequestsApi.copyAttachment, payload.requestId, payload.attachment);
  yield put(SgwRequestActions.attachments.fetch(payload.requestId));
}

function* onDeleteAttachment({ payload }: ReturnType<typeof SgwRequestActions.attachments.delete>): SagaIterator {
  yield call(SgwRequestsApi.deleteAttachment, payload.requestId, payload.attachmentId);
  yield put(SgwRequestActions.attachments.fetch(payload.requestId));
}

function* syncConflictsTimeout() {
  yield delay(SYNC_CONFLICTS_TIMEOUT_MS);
  yield select((store: IRootState) => store.user.user);
  const loading: ISelect<typeof selectConflictsLoading> = yield select(selectConflictsLoading);
  if (loading) {
    yield put(SnackBarActions.setSuccess(translate('sgw.requests.detail.conflicts.syncConflictsTimeoutMessage')));
  }
  yield put(SgwRequestActions.conflicts.setLoading(false));
}

function* syncConflicts(sgwRequestId: number) {
  try {
    yield call(SgwRequestsApi.conflicts.sync, sgwRequestId);
    yield put(SgwRequestActions.conflicts.fetchList(`${sgwRequestId}`));
    yield put(SgwRequestActions.fetch(`${sgwRequestId}`));
  } catch (e: any) {
    const loading: ISelect<typeof selectConflictsLoading> = yield select(selectConflictsLoading);
    if (loading) {
      //@ts-ignore TODO: fix this
      if (e.response) {
        yield put(handleServerError(e));
      }
    }
  } finally {
    yield put(SgwRequestActions.conflicts.setLoading(false));
  }
}

function* onSyncConflicts({ payload }: TPayloadAction<number>): SagaIterator {
  try {
    yield put(SgwRequestActions.conflicts.setLoading(true));
    yield all({
      sync: call(syncConflicts, payload),
      timeout: call(syncConflictsTimeout),
    });
  } finally {
    yield put(SgwRequestActions.conflicts.setLoading(false));
  }
}

function* onFetchConflicts({ payload }: ReturnType<typeof SgwRequestActions.conflicts.fetchList>): SagaIterator {
  const response: ICall<typeof SgwRequestsApi.conflicts.fetchList> = yield call(
    SgwRequestsApi.conflicts.fetchList,
    payload,
  );
  yield put(SgwRequestActions.conflicts.setList(response!.data.data));
}

function* onSaveConflict({
  payload: { requestId, conflictGroup },
}: ReturnType<typeof SgwRequestActions.conflicts.save>): SagaIterator {
  yield call(SgwRequestsApi.conflicts.save, requestId, conflictGroup);
  yield put(SgwRequestActions.conflicts.fetchList(`${requestId}`));
  yield put(SgwRequestActions.fetch(`${requestId}`));
}

function* onSendEmailAP({ payload }: ReturnType<typeof SgwRequestActions.conflicts.sendEmailAP>): SagaIterator {
  try {
    const response: ICall<typeof SgwRequestsApi.conflicts.sendEmailAP> = yield call(
      SgwRequestsApi.conflicts.sendEmailAP,
      payload.requestId,
      payload.conflictId,
    );
    if (response?.status === HttpStatusCodes.OK) {
      yield put(SnackBarActions.setSuccess(translate('sgw.requests.detail.advisingParties.succesEmail')));
      yield put(SgwRequestActions.conflicts.fetchList(`${payload.requestId}`));
    }
  } catch (error) {
    yield put(SnackBarActions.setFailure(translate('sgw.requests.detail.advisingParties.failureEmail')));
  }
}

function* onSendEmailPermit({ payload }: ReturnType<typeof SgwRequestActions.sendEmailPermit>): SagaIterator {
  try {
    const response: ICall<typeof SgwRequestsApi.sendEmailPermit> = yield call(
      SgwRequestsApi.sendEmailPermit,
      payload.requestId,
    );
    if (response?.status === HttpStatusCodes.NO_CONTENT) {
      yield put(
        SnackBarActions.setSuccess(translate('sgw.requests.detail.permitDocumentsPanel.modal.sendEmailSuccess')),
      );
    }
  } catch (error) {
    yield put(SnackBarActions.setFailure(translate('sgw.requests.detail.permitDocumentsPanel.modal.sendEmailFail')));
  }
}

function* onSendFinalInvoice({ payload }: ReturnType<typeof SgwRequestActions.sendFinalInvoice>): SagaIterator {
  try {
    yield call(SgwRequestsApi.sendFinalInvoice, payload);
    yield put(SgwRequestActions.fetch(payload));
    yield put(SnackBarActions.setSuccess(translate('sgw.requests.detail.retributionTable.successFinalInvoice')));
  } catch (error) {
    yield put(SnackBarActions.setFailure(translate('sgw.requests.detail.retributionTable.failFinalInvoice')));
    throw error;
  }
}

function* onFetchConflictGroupMessages({
  payload: { requestId, conflictGroupId },
}: TPayloadAction<{ requestId: number; conflictGroupId: number }>): SagaIterator {
  const response: ICall<typeof SgwRequestsApi.conflicts.fetchMessages> = yield call(
    SgwRequestsApi.conflicts.fetchMessages,
    requestId,
    conflictGroupId,
  );
  yield put(SgwRequestActions.conflicts.setMessage({ ...response!.data.data, id: conflictGroupId }));
}

function* onSaveConflictGroupMessage({
  payload: { conflictGroupId, message },
}: ReturnType<typeof SgwRequestActions.conflicts.saveMessage>): SagaIterator {
  try {
    const response: ICall<typeof SgwRequestsApi.conflicts.saveMessage> = yield call(
      SgwRequestsApi.conflicts.saveMessage,
      message.requestId!,
      conflictGroupId,
      mapToRequestMessageBE(message),
    );
    yield put(SgwRequestActions.conflicts.setMessage({ ...response!.data.data, id: conflictGroupId }));
  } finally {
    yield put(setRequestMessageAttachments(initialRequestsState.requestMessageAttachments));
  }
}

function* onFetchQuarterCost({ payload }: ReturnType<typeof SgwRequestActions.quarterCost.fetch>): SagaIterator {
  const response: ICall<typeof SgwRequestsApi.fetchQuarterCost> = yield call(SgwRequestsApi.fetchQuarterCost, payload);
  yield put(SgwRequestActions.quarterCost.set(response!.data.data));
}

function* onFetchRequestHistory({
  payload,
}: ReturnType<typeof SgwRequestActions.fetchSgwRequestHistory>): SagaIterator {
  try {
    const response: ICall<typeof SgwRequestsApi.fetchSgwRequestHistory> = yield call(
      SgwRequestsApi.fetchSgwRequestHistory,
      payload.requestId,
      payload.sorting,
    );

    yield put(
      SgwRequestActions.setSgwRequestHistory({ history: response!.data.data, total: response!.data.data.length }),
    );
  } catch (error) {
    yield put(SnackBarActions.setFailure(translate('sgw.requests.detail.history.error')));
  }
}

function* onSyncDates({ payload }: ReturnType<typeof SgwRequestActions.syncDates>): SagaIterator {
  if (!payload.phase) yield take(SgwPhasesActions.setList.type);
  yield put(
    SgwRequestActions.patch({
      id: payload.requestId,
      dateFrom: yield select(getLowestDateFrom(payload.phase)),
      dateUntil: yield select(getHighestDateUntil(payload.phase)),
    }),
  );
}

function* onFetchRequestPermitHistory({
  payload,
}: ReturnType<typeof SgwRequestActions.fetchSgwRequestPermitHistory>): SagaIterator {
  const response: ICall<typeof SgwRequestsApi.fetchSgwRequestPermitHistory> = yield call(
    SgwRequestsApi.fetchSgwRequestPermitHistory,
    payload.id,
  );
  yield put(SgwRequestActions.setSgwRequestPermitHistory(response!.data.data));
}

export function* sgwRequestsSaga(): SagaIterator {
  yield takeLatest(SgwRequestActions.attachments.copy.type, genericErrorHandler(onCopyAttachment));
  yield takeLatest(SgwRequestActions.attachments.delete.type, genericErrorHandler(onDeleteAttachment));
  yield takeLatest(SgwRequestActions.attachments.fetch.type, genericErrorHandler(onFetchAttachments));
  yield takeLatest(SgwRequestActions.attachments.save.type, genericErrorHandler(onSaveAttachments));
  yield takeLatest(SgwRequestActions.list.fetch.type, genericErrorHandler(onFetchRequestList));
  yield takeLatest(SgwRequestActions.fetchRequestCounters.type, genericErrorHandler(onFetchRequestCounters));
  yield takeLatest(SgwRequestActions.fetch.type, genericErrorHandler(onFetchRequest));
  yield takeLatest(SgwRequestActions.assign.type, genericErrorHandler(onRequestAssign));
  yield takeLatest(SgwRequestActions.patch.type, genericErrorHandler(onPatchRequest));
  yield takeLatest(SgwRequestActions.postTransition.type, genericErrorHandler(onPostRequestTransition));
  yield takeLatest(SgwRequestActions.fetchSgwRequestMessages.type, genericErrorHandler(onFetchSgwRequestMessages));
  yield takeLatest(SgwRequestActions.saveSgwRequestMessage.type, genericErrorHandler(onSaveSgwRequestMessage));
  yield takeLatest(SgwRequestActions.resolveSgwRequestMessages.type, genericErrorHandler(onResolveSgwRequestMessage));
  yield takeLatest(SgwRequestActions.patchInternalNotes.type, genericErrorHandler(onPatchInternalNotes));
  yield takeLatest(SgwRequestActions.sendEmailPermit.type, genericErrorHandler(onSendEmailPermit));
  yield takeLatest(SgwRequestActions.sendFinalInvoice.type, genericErrorHandler(onSendFinalInvoice));
  yield takeLatest(SgwRequestActions.conflicts.sync.type, onSyncConflicts);
  yield takeLatest(SgwRequestActions.conflicts.fetchList.type, genericErrorHandler(onFetchConflicts));
  yield takeLatest(SgwRequestActions.conflicts.sendEmailAP.type, genericErrorHandler(onSendEmailAP));
  yield takeLatest(SgwRequestActions.conflicts.save.type, genericErrorHandler(onSaveConflict));
  yield takeEvery(SgwRequestActions.conflicts.fetchMessages.type, genericErrorHandler(onFetchConflictGroupMessages));
  yield takeEvery(SgwRequestActions.conflicts.saveMessage.type, genericErrorHandler(onSaveConflictGroupMessage));
  yield takeEvery(SgwRequestActions.quarterCost.fetch.type, genericErrorHandler(onFetchQuarterCost));
  yield takeEvery(SgwRequestActions.fetchSgwRequestHistory.type, genericErrorHandler(onFetchRequestHistory));
  yield takeEvery(SgwRequestActions.syncDates.type, genericErrorHandler(onSyncDates));
  yield takeEvery(
    SgwRequestActions.fetchSgwRequestPermitHistory.type,
    genericErrorHandler(onFetchRequestPermitHistory),
  );
}
