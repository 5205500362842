import axios, { AxiosResponse } from 'axios';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AppActions, genericErrorHandler, handleServerError, TPayloadAction } from '../..';
import { AppFeatureAction, RequestActions } from '../../../store/actions';
import { MetadataActions } from '../../../store/actions/metadata.actions';
import { LoginApi } from '../../../store/api/login.api';
import { getRedirectUrlAfterLogin, selectUser } from '../../../store/selectors';
import { HttpStatusCodes, IBearerToken } from '../../../types';
import { CLIENT_ID, CLIENT_SECRET } from '../../config/environment';
import { appUrls } from '../../config/url.constants';
import LocalStorage from '../../utils/local-storage';
import { UserActions } from '../user/user.actions';
import { initialAppState } from './app.state';

function* onInitApp(): SagaIterator {
  axios.defaults.headers.common['Accept-Language'] = 'nl';
  const accessToken = LocalStorage.get('accessToken');

  if (accessToken) {
    yield put(AppActions.setAccessToken(accessToken));
    yield put(AppActions.initData(false));
    yield put(AppActions.setLoading(false));
  } else if (LocalStorage.get('refreshToken')) {
    yield put(AppActions.fetchToken({ type: 'refreshToken', refreshToken: LocalStorage.get('refreshToken')! }));
  } else {
    if (window.location.pathname !== appUrls.login.base && window.location.pathname !== appUrls.login.oauthMprofile) {
      // Exception for login page
      yield put(push(appUrls.login.selection));
    }
    yield put(AppActions.setLoading(false));
  }
}

function* onInitData({ payload: shouldRedirect }: ReturnType<typeof AppActions.initData>): SagaIterator {
  yield put(UserActions.fetch(shouldRedirect));
  yield put(AppFeatureAction.fetch());
  yield put(MetadataActions.fetch());
}

function* onServerError(action: TPayloadAction<any>): SagaIterator {
  const response: AxiosResponse = action.payload.response;
  // When the token is invalid, we need to refresh the page (so the old back-office will fetch a new token)
  if (response.status === HttpStatusCodes.UNAUTHORIZED) {
    yield put(AppActions.postMessageToParent({ id: 'UNAUTHORIZED' }));
  }
}

function* onLocationChange(action: TPayloadAction<any>): SagaIterator {
  yield put(AppActions.postMessageToParent({ id: 'LOCATION_CHANGE', path: action.payload.location.pathname }));

  if (
    !action.payload.location.pathname.includes(appUrls.reset.password('')) &&
    !action.payload.location.pathname.includes(appUrls.login.base) &&
    !action.payload.location.pathname.includes(appUrls.login.oauthMprofile)
  ) {
    yield put(AppActions.setRedirectUrl(action.payload.location.pathname));
  }
}

// eslint-disable-next-line require-yield
function* onPostMessageToParent(action: TPayloadAction<any>): SagaIterator {
  const parent = window.opener || window.parent || window.top;
  if (parent) {
    parent.postMessage(action.payload, '*');
  }
}

function* onFetchToken({ payload }: ReturnType<typeof AppActions.fetchToken>): SagaIterator {
  let response: { data: IBearerToken | undefined };
  if (payload.type === 'login') {
    response = yield call(LoginApi.fetchToken, {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      ...payload.credentials,
    });
  } else {
    response = yield call(LoginApi.refreshToken, {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      refresh_token: payload.refreshToken,
    });
  }

  const refreshToken = response?.data?.refresh_token;
  if (refreshToken) {
    LocalStorage.set('refreshToken', refreshToken);
  }

  const accessToken = response?.data?.access_token;
  if (accessToken) {
    yield put(AppActions.setAccessToken(accessToken));
    yield put(AppActions.initData(payload.type === 'login'));
    yield put(AppActions.setLoading(false));
  }
}

function* onFetchOAuthToken({ payload }: ReturnType<typeof AppActions.fetchOAuthToken>): SagaIterator {
  let response: { data: IBearerToken | undefined };
  response = yield call(LoginApi.oauthToken, payload);

  const refreshToken = response?.data?.refresh_token;
  if (refreshToken) {
    LocalStorage.set('refreshToken', refreshToken);
  }

  const accessToken = response?.data?.access_token;

  if (accessToken) {
    yield put(AppActions.setAccessToken(accessToken));
    yield put(AppActions.initData(false));
    yield put(AppActions.setLoading(false));
    const redirectUrl = yield select(getRedirectUrlAfterLogin);
    yield put(push(redirectUrl));
  }
}

function onSetToken({ payload }: ReturnType<typeof AppActions.setAccessToken>) {
  axios.defaults.headers.Authorization = payload ? `Bearer ${payload}` : undefined;
  payload ? LocalStorage.set('accessToken', payload) : LocalStorage.remove('accessToken');
}

function* onLogout({ payload: resetRedirectUrl = false }: ReturnType<typeof AppActions.logout>): SagaIterator {
  yield put(AppActions.setLoading(false));
  LocalStorage.remove('refreshToken');
  const user = yield select(selectUser);
  if (user && !user.applicationUser && LocalStorage.get('accessToken')) {
    const redirectUri = `${window.location.origin}${appUrls.login.selection}`;
    const logoutUriResponse = yield call(LoginApi.logoutUrl, redirectUri);
    const logoutUri = logoutUriResponse.data?.url;

    yield put(AppActions.setAccessToken(null));

    if (logoutUri) {
      window.location.href = logoutUri;
      return;
    }
  } else {
    yield put(AppActions.setAccessToken(null));
    yield put(push(appUrls.login.selection));
  }

  yield put(RequestActions.setMetadata(null));
  if (resetRedirectUrl) {
    yield put(AppActions.setRedirectUrl(initialAppState.redirectUrl));
  }
}

export function* appSaga(): SagaIterator {
  yield takeLatest(AppActions.fetchToken.type, genericErrorHandler(onFetchToken));
  yield takeLatest(AppActions.fetchOAuthToken.type, genericErrorHandler(onFetchOAuthToken));
  yield takeLatest(AppActions.logout.type, genericErrorHandler(onLogout));
  yield takeLatest(AppActions.setAccessToken.type, onSetToken);
  yield takeLatest(AppActions.init.type, genericErrorHandler(onInitApp));
  yield takeLatest(AppActions.initData.type, genericErrorHandler(onInitData));
  yield takeLatest(handleServerError.type, genericErrorHandler(onServerError));
  yield takeLatest(LOCATION_CHANGE, genericErrorHandler(onLocationChange));
  yield takeLatest(AppActions.postMessageToParent.type, genericErrorHandler(onPostMessageToParent));
}
