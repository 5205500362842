import { Grid, makeStyles } from '@material-ui/core';
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ClearFiltersFab } from '../../../common/components/buttons/ClearFiltersFab';
import { selectTable } from '../../../store/selectors/utilityCompanies.selectors';
import { SearchTextField } from '../../atoms';
import { IUtilityCompanyFilter } from '../../../types';
import { translate } from '../../../common/translations/translate';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { UtilityCompanyActions } from '../../../store/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  container: {
    marginBottom: '20px',
    alignItems: 'center',
    paddingRight: '20px',
  },
  clear: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export const UtilityCompanyFilters: FC = () => {
  const C = useStyles();
  const { filters } = useSelector(selectTable);

  const dispatch = useDispatch();

  const onChangeFiltering = useCallback(
    (filters: IUtilityCompanyFilter): void => {
      dispatch(UtilityCompanyActions.list.fetch({ filters }));
    },
    [dispatch],
  );

  const resetFilters = useCallback((): void => {
    dispatch(
      UtilityCompanyActions.list.fetch({
        filters: {
          name: '',
          onlyActive: false,
        },
      }),
    );
  }, [dispatch]);

  const nameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeFiltering({
      ...filters,
      name: event.target.value,
    });
  };

  const onlyActiveFilterChange = (checked: boolean) => {
    onChangeFiltering({
      ...filters,
      onlyActive: checked,
    });
  };

  return (
    <Grid container spacing={2} className={C.container}>
      <Grid item xs={3}>
        <SearchTextField value={filters.name} onChange={nameFilterChange} />
      </Grid>
      <Grid item xs={2}>
        <CheckboxWithLabel
          label={translate('utilityCompanies.table.onlyActive')}
          checked={!!filters.onlyActive}
          onChange={(checked: boolean) => onlyActiveFilterChange(checked)}
        />
      </Grid>
      <Grid item xs={2} className={C.clear}>
        <ClearFiltersFab onClick={resetFilters} />
      </Grid>
    </Grid>
  );
};
