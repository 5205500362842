import { Actions, TableActions } from '../../common';
import { ISetting } from '../../types';

class SgwSettingsActionsClass extends Actions {
  list = new TableActions<ISetting>(`${this.prefix}.list`);
  reset = this.createPayloadAction<ISetting>('reset');
  set = this.createPayloadAction<ISetting>('set');
  saveList = this.createAction('saveList');
}

export const SgwSettingsActions = new SgwSettingsActionsClass('sgwSettings');
