import { Divider, FormHelperText, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormikErrors } from 'formik';
import moment from 'moment';
import { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  FormikAutocompleteField,
  FormikCheckboxField,
  FormikDateField,
} from '../../../common/components/formikFormFields';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { FROM_TIMES, toTimeOptions, UNTIL_TIMES } from '../../../common/utils/moment.util';
import { RequestCreateField, IRequestFormData, DateFormat } from '../../../types';
import { Visible } from '../../../common/components/layout/Visible.component';
import { useMetadata } from '../../../hooks';
import { getEditableFieldsIncludes, getSettings, selectUser } from '../../../store/selectors';
import { AsignDatepicker } from '../../../common/components/form-fields/datepicker/asign-datepicker.component';
import { convertDateFormat } from '../../../common/utils/date.util';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { translate } from '../../../common/translations/translate';
import { useLatestValidEndDate } from '../../../hooks/useLatestValidEndDate.hook';

interface IProps {
  errors: FormikErrors<IRequestFormData>;
  values: IRequestFormData;
  setFieldValue: (field: string, value: any) => void;
  validateForm: (values: IRequestFormData) => void;
}

const useStyles = makeStyles({
  container: { width: 550 },
  countryField: { width: 300 },
  infoText: {
    fontSize: 13,
    color: '#9e9e9e',
    fontStyle: 'italic',
  },
  validation: {
    paddingLeft: '16px',
  },
  timeAutoComplete: {
    marginLeft: '10px',
  },
  dateTimeInput: {
    display: 'flex',
    flexDirection: 'column',
  },
  preferredMomentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  preferredMomentClearButton: {
    fontSize: '14px',
  },
});

export const RequestFormDatetimeComponent: FunctionComponent<IProps> = ({
  errors,
  values,
  setFieldValue,
  validateForm,
}) => {
  const C = useStyles();

  const currentUser = useSelector(selectUser);
  const settings = useSelector(getSettings);
  const createRequestWithoutLimitations = currentUser?.acl.createRequestWithoutLimitations;
  const metadata = useMetadata();
  const timeFromDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.timeFrom));
  const timeUntilDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.timeUntil));
  const isPreferredMomentDisabled = !useSelector(getEditableFieldsIncludes(RequestCreateField.entireDay));

  const { latestEndDate } = useLatestValidEndDate(values.dateFrom);

  // this useEffect sets default datefrom and dateUntil
  useEffect(() => {
    if (!values.dateFrom) {
      if (createRequestWithoutLimitations) {
        setFieldValue(RequestCreateField.dateFrom, moment());
      } else if (settings.UTILITY_COMPANY_START_DATE_BUFFER) {
        setFieldValue(
          RequestCreateField.dateFrom,
          moment().add(settings.UTILITY_COMPANY_START_DATE_BUFFER, 'days').format(DateFormat.reduxStoreDateString),
        );
      }
    }

    if (!values.dateUntil) {
      if (createRequestWithoutLimitations) {
        setFieldValue(RequestCreateField.dateUntil, moment());
      } else if (settings.UTILITY_COMPANY_START_DATE_BUFFER) {
        setFieldValue(
          RequestCreateField.dateUntil,
          moment().add(settings.UTILITY_COMPANY_START_DATE_BUFFER, 'days').format(DateFormat.reduxStoreDateString),
        );
      }
    }

    if (
      moment(values.dateFrom, DateFormat.reduxStoreDateString) >
      moment(values.dateUntil, DateFormat.reduxStoreDateString)
    ) {
      setFieldValue(RequestCreateField.dateUntil, values.dateFrom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createRequestWithoutLimitations,
    setFieldValue,
    settings.UTILITY_COMPANY_START_DATE_BUFFER,
    values.dateFrom,
    values.dateUntil,
    values.preferredMoment,
  ]);

  // this useEffect sets default preferredMoment, and listens for dateFrom changes to update preferredMoment accordingly.
  useEffect(() => {
    if (!createRequestWithoutLimitations && !values.preferredMoment && values.dateFrom) {
      const dateFromAt6AM = moment(values.dateFrom, DateFormat.reduxStoreDateString)
        .set({ hour: 6, minute: 0 })
        .format(DateFormat.reduxStoreDateTimeString);
      setFieldValue(RequestCreateField.preferredMoment, dateFromAt6AM);
    }

    if (
      !createRequestWithoutLimitations &&
      values.preferredMoment &&
      moment(values.dateFrom, DateFormat.reduxStoreDateString) !==
        moment(values.preferredMoment, DateFormat.reduxStoreDateString)
    ) {
      const dateFromWithTimeFromPreferredMoment = moment(values.dateFrom, DateFormat.reduxStoreDateString)
        .set({ hour: moment(values.preferredMoment).hour(), minute: 0 })
        .format(DateFormat.reduxStoreDateTimeString);
      setFieldValue(RequestCreateField.preferredMoment, dateFromWithTimeFromPreferredMoment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createRequestWithoutLimitations, setFieldValue, values.dateFrom]);

  // this useEffect validates the form manually when the preferredMoment changes, to prevent incorrect validation due to inconsistent values in the formik state.
  useEffect(() => {
    if (values.preferredMoment) {
      validateForm({
        ...values,
        dateFrom: values.dateFrom,
        dateUntil: values.dateUntil,
        preferredMoment: values.preferredMoment,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateForm, values.preferredMoment, values.dateFrom, values.dateUntil]);

  const startDateBuffer =
    metadata.settings.find((setting) => setting.name === 'UTILITY_COMPANY_START_DATE_BUFFER')?.value || 0;

  const dateFromMinDate = createRequestWithoutLimitations ? moment() : moment().add(startDateBuffer, 'days');

  return (
    <>
      <PanelTitle>{translate('Requests.Create.DateTitle')}</PanelTitle>

      <Grid container className={C.container} spacing={4} justify={'flex-start'}>
        <Grid item xs={12} className={C.infoText}>
          {translate(
            currentUser?.utilityCompany
              ? `Requests.Create.periodRestrictedToMaxDaysFromPermit`
              : `Requests.Create.selectedPeriodStartsBeforeTheNormalProcessingTime`,
          )}
        </Grid>
        <Grid item xs={6}>
          <FormikDateField
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.dateFrom))}
            name={RequestCreateField.dateFrom}
            label={translate(`Requests.Create.Form.dateFrom`)}
            minDate={dateFromMinDate}
            dateFormat={DateFormat.reduxStoreDateString}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormikDateField
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.dateUntil))}
            name={RequestCreateField.dateUntil}
            label={translate(`Requests.Create.Form.dateUntil`)}
            minDate={moment(values.dateFrom || undefined)}
            maxDate={createRequestWithoutLimitations ? undefined : moment(latestEndDate)}
            dateFormat={DateFormat.reduxStoreDateString}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormikCheckboxField
            disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.onlyOnWeekdays))}
            name={RequestCreateField.onlyOnWeekdays}
            label={translate('Requests.Create.Form.onlyOnWeekdays')}
          />
        </Grid>
      </Grid>

      <br />
      <Divider />
      <br />

      <Visible visible={values.reason !== 'container'}>
        <Grid item xs={12} className={C.container}>
          <div className={C.infoText}>{translate('Requests.Create.TimeInfo')}</div>
        </Grid>

        <PanelTitle>{translate('Requests.Create.TimeTitle')}</PanelTitle>
        <Grid container className={C.container} spacing={4} justify={'flex-start'}>
          {!values.entireDay && (
            <>
              <Grid item xs={6}>
                <FormikAutocompleteField
                  disabled={timeFromDisabled}
                  name={RequestCreateField.timeFrom}
                  label={translate(`Requests.Create.Form.timeFrom`)}
                  options={toTimeOptions(FROM_TIMES)}
                  value={`${values.timeFrom}`}
                  error={!!errors.timeFrom}
                />
              </Grid>
              <Grid item xs={6}>
                <FormikAutocompleteField
                  disabled={timeUntilDisabled}
                  name={RequestCreateField.timeUntil}
                  label={translate(`Requests.Create.Form.timeUntil`)}
                  options={toTimeOptions(UNTIL_TIMES)}
                  value={`${values.timeUntil}`}
                  error={!!errors.timeUntil}
                />
              </Grid>
              {(errors.timeUntil || errors.timeFrom) && (
                <FormHelperText error className={C.validation}>
                  {translate('Requests.Create.Validation.TimeOrder')}
                </FormHelperText>
              )}
            </>
          )}
          <Grid item xs={12}>
            <FormikCheckboxField
              disabled={!useSelector(getEditableFieldsIncludes(RequestCreateField.entireDay))}
              name={RequestCreateField.entireDay}
              label={translate('Requests.Create.Form.entireDay')}
            />
          </Grid>
        </Grid>
        {!createRequestWithoutLimitations && (
          <>
            <PanelTitle>{translate('Requests.Create.Form.preferredMoment')}</PanelTitle>
            <Grid container className={C.container} spacing={4} justify={'flex-start'}>
              <Grid item className={C.infoText}>
                {translate('Requests.Create.Form.preferredMomentInfo')}
              </Grid>
              <Grid item className={C.dateTimeInput}>
                <div className={C.preferredMomentContainer}>
                  <AsignDatepicker
                    value={moment(values.preferredMoment, DateFormat.reduxStoreDateString)}
                    onChange={(value) => {
                      console.log('onChange', value);
                      const isValid = moment(value).isValid();
                      const newPreferredMoment = convertDateFormat(
                        value,
                        DateFormat.reduxStoreDateTimeString,
                        DateFormat.reduxStoreDateTimeString,
                      );
                      setFieldValue(RequestCreateField.preferredMoment, isValid ? newPreferredMoment : '');
                    }}
                    disabled={isPreferredMomentDisabled}
                    minDate={values.dateFrom ? moment(values.dateFrom) : moment()}
                    maxDate={values.dateUntil ? moment(values.dateUntil) : moment()}
                    required
                  />
                  <SingleSelectV2Component
                    fullWidth={false}
                    onChange={(e) => {
                      const [hours, minutes] = `${e?.target?.value}`.split(':').map(Number);
                      const newPreferredMoment = moment(values.preferredMoment)
                        .set({ hour: hours, minute: minutes })
                        .format(DateFormat.reduxStoreDateTimeString);
                      setFieldValue(RequestCreateField.preferredMoment, newPreferredMoment);
                    }}
                    menuItems={toTimeOptions(FROM_TIMES)}
                    className={C.timeAutoComplete}
                    value={values.preferredMoment ? moment(values.preferredMoment).format(DateFormat.time) : ''}
                    disabled={isPreferredMomentDisabled}
                  />
                </div>

                {errors.preferredMoment && (
                  <FormHelperText error className={C.validation}>
                    {errors.preferredMoment}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Visible>
    </>
  );
};
