import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AddCircleOutline as AddIcon,
  BuildSharp as Build,
  CloseSharp as Close,
  WarningSharp as Warning,
} from '@material-ui/icons';
import * as I18n from 'i18n-js';
import * as React from 'react';
import { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedDateTime } from '../../../../common';
import { Button } from '../../../../common/components/buttons/asign-button-extensions';
import { ModalButton } from '../../../../common/components/buttons/modal-button';
import { AutocompleteQueryComponent } from '../../../../common/components/form-fields/autocomplete-query.component';
import { Unavailable } from '../../../../common/components/formatters/unavailable';
import { Visible } from '../../../../common/components/layout/Visible.component';
import { PanelTitle } from '../../../../common/components/panel/panel-title.component';
import { appUrls } from '../../../../common/config/url.constants';
import { getIsAuthorized } from '../../../../common/store/user/user.selectors';
import { SIGN_CONDITIONS, SIGN_STATES } from '../../../../signs/signs.constants';
import { SignsActions } from '../../../../signs/store/signs.actions';
import { selectSignsOptions } from '../../../../signs/store/signs.selectors';
import { ISign } from '../../../../signs/types/sign';
import { RequestLocationsActions } from '../../../../store/actions';
import { getRequestAcl } from '../../../../store/selectors';
import {
  IPublicDomainIntakeExtended,
  IPublicDomainIntakeSign,
  IRequestAcl,
  IRequestAclActions,
} from '../../../../types';

interface IProps {
  location?: IPublicDomainIntakeExtended;
}

const useStyles = makeStyles({
  clickableIcon: {
    marginLeft: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icons: {
    textAlign: 'right',
  },
  icon: {
    marginLeft: '10px',
  },
  markAsMissing: {
    textAlign: 'right',
    marginTop: '15px',
  },
  addSignTitle: {
    marginTop: '2em',
  },
  addSignButton: {
    display: 'flex',
    alignItems: 'flex-end',
  },
});

export const SignsComponent: FunctionComponent<IProps> = ({ location }) => {
  const C = useStyles();

  const dispatch = useDispatch();
  const acl: IRequestAcl = useSelector(getRequestAcl(`${location?.permitRequestId}`));
  const [signToAdd, setSignToAdd] = useState<string | null>(null);
  const canEditAssignedRequest = useSelector(getIsAuthorized([IRequestAclActions.editAssignedRequest]));
  const canViewRequest = useSelector(getIsAuthorized([IRequestAclActions.viewRequest]));
  const isReadOnly = (canViewRequest && !canEditAssignedRequest) || !acl?.can_edit_signs;
  const changeSignCallback = useCallback((value: string | null) => {
    setSignToAdd(value);
  }, []);

  const changeSignQueryCallback = useCallback(
    (value?: string) => {
      dispatch(SignsActions.fetchSignsByFilter({ query: value }));
    },
    [dispatch],
  );

  const removeSignHandler = useCallback(
    (signToRemove: ISign) => () => {
      location && dispatch(RequestLocationsActions.removeSign({ location, signId: signToRemove.hash }));
    },
    [dispatch, location],
  );

  const addSignHandler = useCallback(() => {
    if (signToAdd && location) {
      dispatch(RequestLocationsActions.addSign({ location, signId: signToAdd }));
      setSignToAdd(null);
    }
  }, [dispatch, signToAdd, location]);

  const canSend = () => {
    return !!signToAdd;
  };

  const markSignsAsInvalid = useCallback(() => {
    location && dispatch(RequestLocationsActions.markSignsAsMissing(location));
  }, [dispatch, location]);

  let signs = location?.signs ? [...location.signs] : [];
  signs.sort((s1, s2) => Number(s2.active) - Number(s1.active));

  const renderIcons = (sign: IPublicDomainIntakeSign) => {
    return (
      <>
        {sign.sign.state.state === SIGN_STATES.MISSING && (
          <Tooltip title={I18n.t('Requests.Detail.Signs.MissingSign')}>
            <Warning fontSize={'small'} className={C.icon} />
          </Tooltip>
        )}

        {(sign.sign.damaged || sign.sign.condition.condition === SIGN_CONDITIONS.DAMAGED) && (
          <Tooltip title={I18n.t('Requests.Detail.Signs.DamagedSign')}>
            <Build fontSize={'small'} color="error" className={C.icon} />
          </Tooltip>
        )}

        {!sign.pickedUpAt && sign.active && !isReadOnly && (
          <Tooltip title={I18n.t('Requests.Detail.Signs.UnlinkSign')}>
            <Close fontSize={'small'} className={C.clickableIcon} onClick={removeSignHandler(sign.sign)} />
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <Grid container alignItems={'flex-end'} data-testid="LocationLicensePlatesWerkhaven">
      {!location?.signs?.length ? (
        <Unavailable text={I18n.t('Requests.Detail.Signs.NoSigns')} />
      ) : (
        <Grid item sm={12}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                {[
                  { label: 'Number', key: 'referenceId' },
                  { label: 'Battery', key: 'createdAt' },
                  { label: 'Placement', key: 'dateFrom' },
                  { label: 'Pickup', key: 'dateUntil' },
                  { label: 'Empty', key: 'empty' },
                ].map((column) => (
                  <TableCell key={column.key}>{I18n.t('Requests.Detail.Signs.' + column.label)}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {signs.map((sign) => (
                <TableRow key={sign.sign.id} className={sign.pickedUpAt || !sign.active ? 'non-active-sign' : ''}>
                  <TableCell>
                    {!isReadOnly ? (
                      <a href={appUrls.signs.detail(sign.sign.hash, true)} target="_blank" rel="noreferrer">
                        {sign.sign.hash}
                      </a>
                    ) : (
                      sign.sign.hash
                    )}
                  </TableCell>
                  <TableCell>{sign.sign.batteryStatus && <>{sign.sign.batteryStatus}%</>}</TableCell>
                  <TableCell>
                    <FormattedDateTime dateTime={sign.placedAt} />
                  </TableCell>
                  <TableCell>
                    <FormattedDateTime dateTime={sign.pickedUpAt} />{' '}
                  </TableCell>
                  <TableCell className={C.icons}>{renderIcons(sign)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Grid item sm={12} lg={'auto'} className={C.markAsMissing}>
            <ModalButton.MarkAsMissing
              onConfirm={markSignsAsInvalid}
              visible={location?.canMarkSignsAsMissing && !isReadOnly}
              title={I18n.t('Requests.Detail.Signs.MarkAsMissing.Title')}
              buttonTitle={I18n.t('Requests.Detail.Signs.MarkAsMissing.Title')}
              needsReason={false}
              modalBody={I18n.t('Requests.Detail.Signs.MarkAsMissing.Dialog')}
            />
          </Grid>

          <Visible visible={!isReadOnly}>
            <Grid container className={C.addSignTitle}>
              <Grid item xs>
                <PanelTitle>{I18n.t('Requests.Detail.Signs.AddSignTitle')}</PanelTitle>
              </Grid>
            </Grid>
          </Visible>
        </Grid>
      )}
      <Visible visible={!isReadOnly}>
        <Grid container justify="space-between" spacing={4}>
          <Grid item sm={12} lg>
            <AutocompleteQueryComponent
              label={I18n.t('Requests.Detail.Signs.SelectSign')}
              optionsSelector={selectSignsOptions}
              onChange={changeSignCallback}
              onQueryChange={changeSignQueryCallback}
              value={signToAdd}
            />
          </Grid>
          <Grid item sm={12} lg={'auto'} className={C.addSignButton}>
            <Button.Green onClick={addSignHandler} disabled={!canSend()} startIcon={<AddIcon fontSize={'small'} />}>
              {I18n.t('Requests.Detail.Signs.AddSignButton')}
            </Button.Green>
          </Grid>
        </Grid>
      </Visible>
    </Grid>
  );
};
