import { Grid, TextField } from '@material-ui/core';
import * as React from 'react';
import { FunctionComponent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { SingleSelectV2Component } from '../../../common/components/form-fields/single-select-v2.component';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { ConfirmDialog } from '../../../common/components/modal-dialog/confirm-dialog.component';
import { PanelTitle } from '../../../common/components/panel/panel-title.component';
import { getIsAuthorized } from '../../../common/store/user/user.selectors';
import { translate } from '../../../common/translations/translate';
import { formatCurrency } from '../../../common/utils/string.util';
import { IPayment, IPaymentPatchState, IRequestAclActions, PaymentState, PaymentType } from '../../../types';

const styles = {
  singleSelect: {
    marginTop: 0,
  },
};

interface IProps {
  payment: IPayment;
  onPatchPaymentState: (payload: IPaymentPatchState) => void;
  requestId?: number;
}

export const PaymentDetails: FunctionComponent<IProps> = ({ payment, onPatchPaymentState, requestId }) => {
  const [reasonForManualEdit, setReasonForManualEdit] = useState<string>('');
  const [newPaymentState, setNewPaymentState] = useState<PaymentState>();
  const [reasonDialogVisible, setReasonDialogVisible] = useState<boolean>(false);
  const canChangeRequestPaymentStatus = useSelector(getIsAuthorized([IRequestAclActions.changeRequestPaymentStatus]));

  const onChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as PaymentState;
    if (value !== payment.state.state) {
      setNewPaymentState(value);
      setReasonDialogVisible(true);
    }
  };

  const onConfirm = useCallback(() => {
    requestId &&
      newPaymentState &&
      onPatchPaymentState({
        id: `${payment.id}`,
        reasonForManualEdit,
        state: newPaymentState,
        requestId: requestId,
      });
    setReasonDialogVisible(false);
    setReasonForManualEdit('');
  }, [requestId, newPaymentState, onPatchPaymentState, reasonForManualEdit, payment]);

  const onChangeReason = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    setReasonForManualEdit(event.target.value as string);
  }, []);

  const hideReasonDialog = useCallback(() => {
    setReasonDialogVisible(false);
  }, []);

  return (
    <>
      <PanelTitle>{translate('Requests.Detail.Payments.Information.Title')}</PanelTitle>
      <Grid container spacing={2}>
        <LabelValue labelCentered label={translate('Requests.Detail.Payments.Information.Status')}>
          <SingleSelectV2Component
            disabled={!canChangeRequestPaymentStatus}
            data-testid="paymentStatesSelect"
            style={styles.singleSelect}
            onChange={onChangeStatus}
            value={payment.state.state}
            menuItems={[
              { value: PaymentState.new, label: translate('Requests.PaymentStates.new') },
              { value: PaymentState.processing, label: translate('Requests.PaymentStates.processing') },
              { value: PaymentState.processed, label: translate('Requests.PaymentStates.processed') },
              { value: PaymentState.overpaid, label: translate('Requests.PaymentStates.overpaid') },
              { value: PaymentState.partially_paid, label: translate('Requests.PaymentStates.partially_paid') },
              { value: PaymentState.closed, label: translate('Requests.PaymentStates.closed') },
              { value: PaymentState.pending, label: translate('Requests.PaymentStates.pending') },
              { value: PaymentState.declined, label: translate('Requests.PaymentStates.declined') },
              { value: PaymentState.invalid, label: translate('Requests.PaymentStates.invalid') },
              { value: PaymentState.cancelled, label: translate('Requests.PaymentStates.cancelled') },
              { value: PaymentState.accepted, label: translate('Requests.PaymentStates.accepted') },
              { value: PaymentState.uncertain, label: translate('Requests.PaymentStates.uncertain') },
              { value: PaymentState.partialpaid, label: translate('Requests.PaymentStates.partialpaid') },
              { value: PaymentState.timedout, label: translate('Requests.PaymentStates.timedout') },
            ].filter(({ value }) => [...payment.state.transitions, payment.state.state].includes(value))}
          />
        </LabelValue>
        <LabelValue
          visible={payment.ogm !== undefined || payment.cartPaymentId !== undefined}
          label={translate('Requests.Detail.Payments.Information.PaymentCharacteristic')}
        >
          {payment.type?.type === PaymentType.online ? payment.cartPaymentId : payment.ogm}
        </LabelValue>
        <LabelValue
          visible={payment.type !== undefined}
          label={translate('Requests.Detail.Payments.Information.PaymentMethod')}
        >
          {payment.type?.name}
        </LabelValue>
        <LabelValue
          visible={payment.cartOrderId !== undefined}
          label={translate('Requests.Detail.Payments.Information.CartOrderId')}
        >
          {payment.cartOrderId}
        </LabelValue>
        <LabelValue
          visible={payment.id !== undefined}
          label={translate('Requests.Detail.Payments.Information.PaymentId')}
        >
          {payment.id}
        </LabelValue>
        <LabelValue
          visible={payment.amountPaid !== undefined}
          label={translate('Requests.Detail.Payments.Information.AmountPaid')}
        >
          {formatCurrency(payment.amountPaid)}
        </LabelValue>
        <LabelValue
          visible={payment.amountToBePaid !== undefined}
          label={translate('Requests.Detail.Payments.Information.TotalAmount')}
        >
          {formatCurrency(payment.amountToBePaid)}
        </LabelValue>
        <LabelValue
          visible={payment.reasonForManualEdit !== undefined}
          label={translate('Requests.Detail.Payments.Information.ReasonForManualEdit')}
        >
          {payment.reasonForManualEdit}
        </LabelValue>
      </Grid>
      <ConfirmDialog
        visible={reasonDialogVisible}
        onClose={hideReasonDialog}
        onConfirm={onConfirm}
        title={translate('Requests.Detail.Payments.ManualEdit.Title')}
        okButtonText={translate('Requests.Detail.Payments.ManualEdit.Confirm')}
      >
        <TextField
          fullWidth
          placeholder={translate('Requests.Detail.Payments.ManualEdit.Description')}
          value={reasonForManualEdit}
          onChange={onChangeReason}
        />
      </ConfirmDialog>
    </>
  );
};
