import axios from 'axios';
import { CONFIG, IApiPromise } from '../../common';
import { ISetting } from '../../types/sgwSetting.types';

const baseUrl = `${CONFIG.backOfficeApi}/v1/sgw/manage/settings`;

export const SgwSettingsApi = {
  fetchList: (): IApiPromise<ISetting[]> => axios.get(baseUrl),
  saveList: (settings: ISetting[]): IApiPromise<ISetting[]> => axios.patch(baseUrl, { settings }),
};
